<template>
  <div class="flex justify-content-between align-items-center contact-us">
    <p 
      class="contact-us__text contact-us__text--msg"
      :class="{ 'color-black' : getPartner }"
    >
      {{ msg }}
    </p>
    <img
      src="@/assets/icons/phone-icon.svg" 
      class="contact-us__phone-icon--default"
    >
    <a 
      class="contact-us__phone-text" 
      :class="[defaultClass ? 'menu-phone-text--default' : '']"
      :href="`tel:${telephoneNumber}`"
    >
      {{ friendlyTelephoneNumber }}
    </a>
    <OpeningHoursMessage class="contact-us__text contact-us__text--opening-msg" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import OpeningHoursMessage from '@/components/shared/OpeningHoursMessage.vue'
export default {
  name: 'ContactUs',
  components: {
    OpeningHoursMessage
  },
  props: {
    telephone: {
      type: String,
      required: true
    },
    friendlyTelephone: {
      type: String,
      required: true
    },
    defaultClass: {
      type: Boolean,
      default: true
    },
    msg: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getPartner']),
    telephoneNumber () {
      // use partner telephone if a partner
      if (this.getPartner) {
        return this.getPartner.telephoneNumber
      } else {
      // use number from props  
        return this.telephone
      }
    },
    friendlyTelephoneNumber () {
      // use partner friendly telephone if a partner
      if (this.getPartner) {
        return this.getPartner.telephoneNumber.match(/([0-9]{4})([0-9]{3})([0-9]*)/).splice(1).join(' ')
      } else {
      // use friendly telephone from props
        return this.friendlyTelephone 
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.contact-us {
  &__text {
    font-size: 13px;

    &--msg {
      color: $text-color-orange;
    }

    &--opening-msg {
      color: $main-title-color;
      margin-top: 7px;
    }

  }

  &__phone-text {
      color: $main-title-color;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 31.68px;

      &--default {
        font-size: 20px;
      }
  }

  &__phone-icon--default {
    width: 12px;
    transform: rotate(24deg);
    position: relative;
    top: -2px;
  }

}

</style>
