<template>
  <div>
    <div 
      v-for="(bullet, i) in bulletPoints"
      :key="i"
      class="bullet-point"
    >
      <div 
        :class="[`bullet-point__point--${bulletColor}`, partner ? `${partner.className}__bg` : '']"
        class="bullet-point__point"
      >
        {{ i + 1 }}
      </div>
      <div class="bullet-point__content">
        {{ bullet }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BulletPointOrdered',
  props: {
    bulletPoints: {
      type: Array,
      required: true
    },
    bulletColor: {
      type: String,
      default: 'orange'
    },
    partner: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
    .bullet-point {
      display: flex;
      &__point {
        min-width: 25px;
        min-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 50%;
        align-self: start;
        margin-right: 12px;

        &--orange {
          background-color: $color-0;
        }
      }

      &__content {
        font-size: 14px;
        line-height: 20px;
      }
    }
</style>
