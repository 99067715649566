import { openingTime, closingTime } from '@/helpers/constants/openingHours'
import isBankHoliday from '@/helpers/openTimes/isBankHoliday'

const isOpeningHours = (open, close, testTime) => {
  let openingHour
  let closingHour
  // option to pass open/close used for tests
  // openingTime/closingTime should be used
  if (open) {
    openingHour = open
  } else {
    openingHour = openingTime 
  }

  if (close) {
    closingHour = close
  } else {
    closingHour = closingTime
  }

  const d = new Date() // get the time/date
  const day = d.getDay() // get day 0 - 6, 0 = sunday

  let currentTime 

  if (testTime) {
    currentTime = testTime
  } else {
    currentTime = String(Date()).slice(16, 21) // get time 24hr e.g 14:50
    currentTime = currentTime.replace(':', '') // format time 1450
  }

  const sunday = 0
  const saturday = 6
  const todaysDate = d.toISOString().slice(0, 10) // YYYY-MM-DD
  // check if bank holiday
  if (isBankHoliday(todaysDate)) {
    return false
  }
  // check if sunday or saturday
  if (day === sunday || day === saturday) {
    return false
  } else {
  // if within opening and closing hours
    if (currentTime > openingHour && currentTime < closingHour) {
      return true
    } else {
    // outside closing hours
      return false
    }
  }
}

export default isOpeningHours
